.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;

  margin-top: 1rem;
}

.control input,
.control textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 100%;
}

.liveCheckbox {
  display: inline-block;
  width: 2rem;
  cursor: pointer;
}

.liveCheckboxLabel {
  display: inline-block;
}

.liveAboutButton {
  margin-left: 2rem;
  font-weight: normal;
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}