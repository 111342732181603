.subNav {
  padding: 0.5rem
}

.subNavLogoutButton {
  text-align: right;
}

.subNavAuthButton {
  font-size: 1.25rem;
  border: 0.5px solid lightgray;
  border-radius: 2px;
  margin-left: 1rem;
  padding: 0.25rem;
}

.subNavAuthButtonIcon {
  margin-right: 0.25rem;
}

.subNavSpacer {
  margin-bottom: 0.5rem;
}

.subNavTrialAd {
  margin-top: 1rem;
  padding: 0.5rem;
}
.subNavTrialAdTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
  text-decoration: underline 2px;
}

.subNavAboutSubscriptionButton {
  padding-top: 0.5rem;
  cursor: pointer;
}

.subNavAboutSubscriptionContainer {
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 1.25rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background-color: ghostwhite;
}

.subNavAboutSubscriptionTitle {
  text-align: center;
  font-weight: bold;
}

@media (min-width: 768px) {
  .subNavAboutSubscriptionContainer {
    width: 40rem;
    /* left: 15%;
    right: 15%; */
    margin: 0.5rem auto;
    /* left: calc(50% - 20rem);
    right: calc(50% - 20rem); */
  }
}