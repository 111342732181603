
.aboutRemeetContent {
  width: 100%;
  max-width: 40rem;
  margin: 1rem auto;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background-color: ghostwhite;
}

.aboutRemeetTitle {
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;

  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  
}

.aboutRemeetImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 0;
}

.aboutRemeetImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .aboutRemeetImageContainer {
    flex-direction: row;
  }

  .aboutRemeetImage {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}