.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #77002e;
  background-color: rgba(0, 0, 0, 0.75);
  background-color: rgb(0, 54, 29);
  /* padding: 0 10%; */
} 

.logo {
  /* font-size: 2rem; */
  color: white;
  font-weight: bold;
  padding-left: 1rem;
}

/* .header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
} */

.header li {
  margin-left: 0.5rem;
}

.header a {
  text-decoration: none;
  /* font-size: 1.5rem; */
  font-weight: bold;
  color: #fcb8d2;
  color: white;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}

.header nav {
  padding-right: 1rem;
}

.mainNavigationIcon {
  margin-right: 0.25rem;
}

.mainNavList {
  display: none
}

.mobileNav {
  display: flex;
}

.menuBtn {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  display: none;
  display: flex;
  cursor: pointer;
}

.menuBtn span {
  width: 100%;
  height: 3px;
  background-color: white;
}

@media (min-width: 30rem) {
  .menuBtn {
    display: none;
  }
  .mobileNav {
    display: none;
  }
  .mainNavList {
    display: inherit;
  }
}